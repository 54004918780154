body {
  padding: 0;
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
  outline: 0;
  vertical-align: baseline;
  overflow-x: hidden!important;
}

body, html {
  height: 100%;
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
